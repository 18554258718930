<template>
	<div>
		<!-- Page Header-->
		<main-nav :activeIndex="activeIndex"></main-nav>
		<el-carousel trigger="click" class="index-slide-container" height="700px">
			<el-carousel-item>
				<div class="slide-01">
					<!--              <img src="../../assets/images/index-slide-01.png" alt="">-->
					<video src="../../assets/video/website-bg.mp4" loop="" autoplay="" playsinline="" muted=""></video>
					<div class="text-area">
						<h1 data-caption-delay="0" class="banner-01-header-text wow fadeInLeft">帮助客户打造卓越</h1>
						<p data-caption-delay="100" class="banner-01-subheader-text wow fadeInRight">用智慧连接每一个人的梦想</p>
						<div class="button-more wow fadeInUp" @click="$router.push('/about')">
							<div>了解更多</div>
						</div>
					</div>
				</div>
			</el-carousel-item>
		</el-carousel>

		<!-- Years of experience-->
		<section class="section section-about wow fadeInDown">
			<div class="container">
				<h2 class="container-header">智慧创造价值 创新点亮未来</h2>
				<router-link to="/about" class="container-sub-header link justify-content-center align-items-center">
					<span>了解东信创智</span>
					<i class="ri-arrow-right-line"></i>
				</router-link>
				<div class="justify-content-center align-items-center">
					<div class="percent-items">
						<span class="text-img" ref="countMain">
							<vue-particles color="#fff" :particleOpacity="0.7" :particlesNumber="400" shapeType="circle"
								:particleSize="2" linesColor="#fff" :linesWidth="1" :lineLinked="true"
								:lineOpacity="0.4" :linesDistance="50" :moveSpeed="10" :hoverEffect="true"
								hoverMode="grab" :clickEffect="true" clickMode="push" class="particles-number">
							</vue-particles>
							<count-to ref="mainCountTo" :autoplay="false" :end-val="90" :duration="2000"
								class="counter markWebBold"></count-to>
						</span>
						<div class="percent wow fadeInUp">
							<h3 class="percent-symbol markWebBold">%</h3>
							<p>覆盖国内整车厂</p>
						</div>
					</div>
				</div>
				<div class="intro-num" ref="countChild">
					<div class="index-about-area wow fadeInRight">
						<img src="../../assets/images/index/index-about-building.png" alt="">
						<div>
							<div class="intro-detail">
								<count-to ref="companyCountTo" :autoplay="false" :end-val="6"
									:duration="2000"></count-to>家
							</div>
							<div class="intro-title">分公司及办事处</div>
						</div>
					</div>
					<div class="index-about-area wow fadeInRight">
						<img src="../../assets/images/index/index-about-develop.png" alt="">
						<div>
							<div class="intro-detail">
								<count-to ref="fastCountTo" :autoplay="false" :end-val="11"
									:duration="2000"></count-to>年
							</div>
							<div class="intro-title">高速稳健发展</div>
						</div>
					</div>
					<div class="index-about-area plus wow fadeInRight">
						<img src="../../assets/images/index/index-about-cooperate.png" alt="">
						<div>
							<div class="intro-detail">
								<count-to ref="doneCountTo" :autoplay="false" :end-val="400"
									:duration="2000"></count-to>家
							</div>
							<div class="intro-title">企业达成合作</div>
						</div>
					</div>
					<div class="index-about-area plus">
						<img src="../../assets/images/index/index-about-talent.png" alt="">
						<div>
							<div class="intro-detail">
								<count-to ref="memberCountTo" :autoplay="false" :end-val="200"
									:duration="2000"></count-to>名
							</div>
							<div class="intro-title">高技术人才</div>
						</div>
					</div>
				</div>
			</div>
			<div class="left-bg">
				<img src="../../assets/images/index-about-bg-01.png" alt="">
			</div>
			<div class="right-bg">
				<img src="../../assets/images/index-about-bg-02.png" alt="">
			</div>
		</section>
		<section class="section section-sm bg-blue-1 section-solution index-section-solution">
			<div class="container solutions-container">
				<h2 class="container-header-2 text-center">打造一站式解决方案平台</h2>
				<div class="container-sub-header justify-content-center align-items-center">
					<span>本地化 | 快速化 | 定制化 | 产品化</span>
				</div>
				<div class="solutions-list">
					<el-row :gutter="0">
						<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
							<el-row :gutter="0">
								<el-col :span="12">
									<div class="index-solutions index-solutions-eea wow fadeInLeft"
										@click="$router.push('/solution/electricalArchitecture')"
										@mouseenter="serviceEea = true" @mouseleave="serviceEea = false">
										<div>
											<template v-if="serviceEea">
												<img class="title-img"
													src="../../assets/images/index/index-solutions-eea-hover.png"
													alt="">
											</template>
											<template v-else>
												<img class="title-img"
													src="../../assets/images/index/index-solutions-eea.png" alt="">
											</template>
											<p>电子电气架构开发</p>
										</div>
										<div class="inner-caption">
											<i class="a"></i>
											<i class="b"></i>
											<i class="c"></i>
											<i class="d"></i>
											<img src="../../assets/images/index/index-solutions-eea-hover.png" alt="">
											<p class="inner-title">电子电气架构开发</p>
											<p class="detail-text">
												东信创智基于先进的技术和丰富的项目经验，开发整车电子电气架构平台，开发内容包括功能架构、物理架构、网络架构和SOA架构。</p>
										</div>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="index-solutions index-solutions-embed wow fadeInLeft"
										@click="$router.push('/solution/controllerSoftware')"
										@mouseenter="serviceEmbeded = true" @mouseleave="serviceEmbeded = false"
										data-wow-delay=".15s">
										<div>
											<template v-if="serviceEmbeded">
												<img class="title-img"
													src="../../assets/images/index/index-solutions-embed-hover.png"
													alt="">
											</template>
											<template v-else>
												<img class="title-img"
													src="../../assets/images/index/index-solutions-embed.png" alt="">
											</template>
											<p class="inner-title">嵌入式软件开发</p>
										</div>
										<div class="inner-caption">
											<i class="a"></i>
											<i class="b"></i>
											<i class="c"></i>
											<i class="d"></i>
											<img src="../../assets/images/index/index-solutions-embed-hover.png" alt="">
											<p class="inner-title">嵌入式软件开发</p>
											<p class="detail-text">
												东信创智基于多年的汽车行业嵌入式开发及AUTOSAR经验，可为客户提供AUTOSAR基础软件工程服务、控制器底层软件开发解决方案、快速原型开发服务等。
											</p>
										</div>
									</div>
								</el-col>
								<el-col :span="24">
									<div class="index-solutions index-solutions-eea index-solutions-sim wow fadeInLeft"
										@click="$router.push('/solution/adas')" @mouseenter="serviceSim = true"
										@mouseleave="serviceSim = false" data-wow-delay=".3s">
										<div>
											<template v-if="serviceSim">
												<img class="title-img"
													src="../../assets/images/index/index-solutions-sim-hover.png"
													alt="">
											</template>
											<template v-else>
												<img class="title-img"
													src="../../assets/images/index/index-solutions-sim.png" alt="">
											</template>
											<p>仿真测试</p>
										</div>
										<div class="inner-caption inner-caption-horizontal">
											<i class="a"></i>
											<i class="b"></i>
											<i class="c"></i>
											<i class="d"></i>
											<div class="inner-horizontal-title">
												<img src="../../assets/images/index/index-solutions-sim-hover.png"
													alt="">
												<p class="inner-horizontal-title" style="margin-top: -15px;">仿真测试</p>
											</div>
											<p class="detail-text">
												覆盖从新能源动力电控、底盘电控、ADAS、自动驾驶、智能座舱、智能网联等整车全域电控系统的各种在环仿真测试解决方案，如MIL、SIL、HIL、VIL、云仿真等。
											</p>
										</div>
									</div>
								</el-col>
							</el-row>
						</el-col>
						<el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
							<div class="index-solutions index-solutions-net wow fadeInRight"
								@click="$router.push('/solution/automotiveNetwork')" @mouseenter="serviceNet = true"
								@mouseleave="serviceNet = false" data-wow-delay=".4s">
								<div>
									<template v-if="serviceNet">
										<img class="title-img"
											src="../../assets/images/index/index-solutions-net-hover.png" alt="">
									</template>
									<template v-else>
										<img class="title-img" src="../../assets/images/index/index-solutions-net.png"
											alt="">
									</template>
									<p>网络测试</p>
								</div>
								<div class="inner-caption">
									<i class="a"></i>
									<i class="b"></i>
									<i class="c"></i>
									<i class="d"></i>
									<img src="../../assets/images/index/index-solutions-net-hover.png" alt="">
									<p class="inner-title">网络测试</p>
									<p class="detail-text">东信创智依托多年网络测试经验，将外部优势资源（Vector、Spirent、Rohde &
										Schwarz等）与自身深厚的车载网络测试开发能力进行整合，为客户提供CAN/CANFD/LIN测试、车载ETH测试、OTA测试等解决方案。</p>
								</div>
							</div>
							<div class="index-solutions index-solutions-info wow fadeInRight"
								@click="$router.push('/solution/securityTest')" @mouseenter="serviceInfo = true"
								@mouseleave="serviceInfo = false" data-wow-delay=".4s">
								<div>
									<template v-if="serviceInfo">
										<img class="title-img"
											src="../../assets/images/index/index-solutions-info-hover.png" alt="">
									</template>
									<template v-else>
										<img class="title-img" src="../../assets/images/index/index-solutions-info.png"
											alt="">
									</template>
									<p>信息安全测试</p>
								</div>

								<div class="inner-caption inner-caption-horizontal">
									<i class="a"></i>
									<i class="b"></i>
									<i class="c"></i>
									<i class="d"></i>
									<div class="inner-horizontal-title">
										<img src="../../assets/images/index/index-solutions-info-hover.png" alt="">
										<p style="margin-top: -20px;padding-left: 5px;padding-right: 5px">信息安全测试</p>
									</div>
									<p class="detail-text inner-horizontal-text">
										随着车载信息安全的重要性的体现，东信创智相继拓展了基于车内总线信息安全及基于传统信息安全相关测试业务。</p>
								</div>
							</div>
						</el-col>
						<el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
							<div class="index-solutions index-solutions-vehicle wow fadeInRight"
								@click="$router.push('/solution/toolSoftware')" @mouseenter="servicePlatform = true"
								@mouseleave="servicePlatform = false" data-wow-delay=".6s">
								<div>
									<template v-if="servicePlatform">
										<img class="title-img"
											src="../../assets/images/index/index-solutions-platform-hover.png" alt="">
									</template>
									<template v-else>
										<img class="title-img"
											src="../../assets/images/index/index-solutions-platform.png" alt="">
									</template>
									<p>应用软件</p>
								</div>
								<div class="inner-caption">
									<i class="a"></i>
									<i class="b"></i>
									<i class="c"></i>
									<i class="d"></i>
									<img src="../../assets/images/index/index-solutions-platform-hover.png" alt="">
									<p class="inner-title">应用软件</p>
									<p class="detail-text">支撑汽车电子设计、开发、仿真、测试V流程体系，为提升工作效率和质量提供有力支撑。</p>
								</div>
							</div>
							<div class="index-solutions index-solutions-platform wow fadeInRight"
								@click="$router.push('/solution/vehicleTest')" @mouseenter="serviceVehicle = true"
								@mouseleave="serviceVehicle = false" data-wow-delay=".6s">
								<div>
									<template v-if="serviceVehicle">
										<img class="title-img"
											src="../../assets/images/index/index-solutions-vehicle-hover.png" alt="">
									</template>
									<template v-else>
										<img class="title-img"
											src="../../assets/images/index/index-solutions-vehicle.png" alt="">
									</template>
									<p>整车测试</p>
								</div>
								<div class="inner-caption">
									<i class="a"></i>
									<i class="b"></i>
									<i class="c"></i>
									<i class="d"></i>
									<img src="../../assets/images/index/index-solutions-vehicle-hover.png" alt="">
									<p class="inner-title">整车测试</p>
									<p class="detail-text">
										面向整车厂及零部件供应商提供的整车电子电气测试服务，帮助客户提升整车功能的可靠性。业务包括辅助客户搭建测试流程体系、提供部件或系统级的网络诊断和功能测试服务。
									</p>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</section>

		<section class="section section-service text-center wow fadeInDown" :class="'bg-service-0' + activeService">
			<div class="container">
				<h2 class="container-header-2 text-white">提供高质高效产品服务</h2>
				<div class="container-sub-header justify-content-center align-items-center">
					<span>用技术搭建市场基石</span>
				</div>
				<div class="all-function mobile-service">
					<swiper :options="swiperOption" ref="mySwiper" onChange="checkActiveSwiper">
						<swiper-slide>
							<div class="service-block service-block-hover">
								<article class="wow fadeInLeft">
									<div>
										<p class="service-title">DYNA4</p>
									</div>
									<div class="service-block-detail">
										<p>虚拟车辆测试的仿真环境</p>
										<p class="detail-text">
											DYNA4是Vector公司旗下产品之一，应用领域包括驾驶员辅助系统及自动驾驶系统的域控制器测试验证、底盘控制系统的域控制器测试验证、整车能量管理的测试验证、内燃发动机控制器的测试验证等。
										</p>
										<el-button size="small" class="btn-learn-more"
											@click="$router.push('/product/DYNA4')">了解更多</el-button>
									</div>
								</article>
							</div>
						</swiper-slide>
						
						<swiper-slide>
							<div class="service-block service-block-hover">
								<article class="wow fadeInLeft">
									<div>
										<p class="service-title">VectorCAST</p>
									</div>
									<div class="service-block-detail">
										<p>高质量软件的自动化代码测试工具</p>
										<p class="detail-text">
											VectorCAST是德国Vector公司旗下的一款权威的C/C++代码的自动化测试套件，在完整的Vector测试解决方案中扮演重要角色。</p>
										<el-button size="small" class="btn-learn-more"
											@click="$router.push('/product/vectorCast')">了解更多</el-button>
									</div>
								</article>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="service-block service-block-hover">
								<article class="wow fadeInLeft">
									<div>
										<p class="service-title">Qt</p>
									</div>
									<div class="service-block-detail">
										<p>为开发者构建高性能的软件应用和嵌入式设备提供全方位工具</p>
										<p class="detail-text">
											Qt是一个基于C++领先的跨平台软件开发框架，旨在为开发者构建高性能的软件应用和嵌入式设备提供一整套从设计、开发、测试到部署的全方位工具。</p>
										<el-button size="small" class="btn-learn-more"
											@click="$router.push('/product/Qt')">了解更多</el-button>
									</div>
								</article>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="service-block service-block-hover">
								<article class="wow fadeInLeft">
									<div>
										<p class="service-title">VTD</p>
									</div>
									<div class="service-block-detail">
										<p>提供高保真的虚拟交通场景，高精度的物理级传感器模型，<br>智慧交通流等，用于自动驾驶及ADAS系统的仿真测试与验证</p>
										<p class="detail-text">
											VTD是世界上使用广泛的开放平台，用于创建、配置和动画化仿真环境以及用于ADAS和自动驾驶汽车的培训、测试和验证的场景。</p>
										<el-button size="small" class="btn-learn-more"
											@click="$router.push('/product/VTD')">了解更多</el-button>
									</div>
								</article>
							</div>
						</swiper-slide>
						
						<!-- 根据情况引入控件 -->
						<div class="swiper-pagination" slot="pagination"></div>
					</swiper>
				</div>
				<el-row class="pc-service">
					
					<el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
						<div class="service-block" @mouseenter="activeService = 1"
							:class="activeService == 1?'service-block-hover':''">
							<article class="wow fadeInLeft">
								<div>
									<p class="service-title">DYNA4</p>
								</div>
								<div class="service-block-detail">
									<p>虚拟车辆测试的仿真环境</p>
									<p class="detail-text">
										DYNA4是Vector公司旗下产品之一，应用领域包括驾驶员辅助系统及自动驾驶系统的域控制器测试验证、底盘控制系统的域控制器测试验证、整车能量管理的测试验证、内燃发动机控制器的测试验证等。
									</p>
									<el-button size="small" class="btn-learn-more"
										@click="$router.push('/product/DYNA4')">了解更多</el-button>
								</div>
							</article>
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
						<div class="service-block" @mouseenter="activeService = 2"
							:class="activeService == 2?'service-block-hover':''">
							<article class="wow fadeInLeft">
								<div>
									<p class="service-title">VectorCAST</p>
								</div>
								<div class="service-block-detail">
									<p>高质量软件的自动化代码测试工具</p>
									<p class="detail-text">
										VectorCAST是德国Vector公司旗下的一款权威的C/C++代码的自动化测试套件，在完整的Vector测试解决方案中扮演重要角色。</p>
									<el-button size="small" class="btn-learn-more"
										@click="$router.push('/product/vectorCast')">了解更多</el-button>
								</div>
							</article>
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
						<div class="service-block" @mouseenter="activeService = 3"
							:class="activeService == 3?'service-block-hover':''">
							<article class="wow fadeInLeft">
								<div>
									<p class="service-title">Qt</p>
								</div>
								<div class="service-block-detail">
									<p>为开发者构建高性能的软件应用<br>和嵌入式设备提供全方位工具</p>
									<p class="detail-text">
										Qt是一个基于C++领先的跨平台软件开发框架，旨在为开发者构建高性能的软件应用和嵌入式设备提供一整套从设计、开发、测试到部署的全方位工具。</p>
									<el-button size="small" class="btn-learn-more"
										@click="$router.push('/product/Qt')">了解更多</el-button>
								</div>
							</article>
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
						<div class="service-block" @mouseenter="activeService = 4"
							:class="activeService == 4?'service-block-hover':''">
							<article class="wow fadeInLeft">
								<div>
									<p class="service-title">VTD</p>
								</div>
								<div class="service-block-detail">
									<p>提供高保真的虚拟交通场景，高精度的物<br>理级传感器模型，智慧交通流等，用于自<br>动驾驶及ADAS系统的仿真测试与验证</p>
									<p class="detail-text">VTD是世界上使用广泛的开放平台，用于创建、配置和动画化仿真环境以及用于ADAS和自动驾驶汽车的培训、测试和验证的场景。
									</p>
									<el-button size="small" class="btn-learn-more"
										@click="$router.push('/product/VTD')">了解更多</el-button>
								</div>
							</article>
						</div>
					</el-col>
					
					
				</el-row>
			</div>
		</section>

		<!-- Latest Projects-->
		<section class="section section-sm section-fluid section-news text-center">
			<div class="container">
				<h2 class="container-header-2">公司资讯</h2>
				<div class="container-sub-header justify-content-center align-items-center">
					<span>谱写高质量发展新篇章</span>
				</div>
				<el-row :gutter="30">
					<el-col :span="8">
						<article class="news-block" @click="linkUrl(newsUrls[0])">
							<img src="../../assets/images/news-01.jpeg" alt="" />
							<div class="news-title">东信创智荣获2024年辽宁省“瞪羚企业”称号</div>
							<!--              <img src="../../assets/images/news-04.jpg" alt="" />-->
							<!--              <div class="news-title">直播预告丨OTA自动化测试解决方案</div>-->
						</article>
					</el-col>
					<el-col :span="8">
						<article class="news-block" @click="linkUrl(newsUrls[1])">
							<img src="../../assets/images/news-03.jpeg" alt="" />
							<div class="news-title">回望2024丨实干笃行，固本兴新</div>
						</article>
					</el-col>
					<el-col :span="8">
						<article class="news-block" @click="linkUrl(newsUrls[2])">
							<img src="../../assets/images/news-02.jpeg" alt="" />
							<div class="news-title">携手十一载，筑梦新征程丨东信创智十一周年庆典圆满举行</div>
						</article>
					</el-col>
				</el-row>
			</div>
		</section>

		<section class="section section-bzinfo section-bzinfo-index text-center">
			<div class="container">
				<h2 class="container-header-2 text-white">业务咨询</h2>
				<div class="container-sub-header justify-content-center align-items-center">
					<span>优质方案，解决所有技术难点</span>
				</div>
				<div class="button-more animate__animated animate__fadeInUp" @click="linkUrl(chatUrl)">立即咨询</div>
			</div>
		</section>
		<!-- Page Footer-->
		<main-footer></main-footer>
	</div>
</template>
<script>
	import mainNav from '@/components/MainNav'
	import mainFooter from '@/components/MainFooter'
	import countTo from 'vue-count-to'
	export default {
		components: {
			mainNav,
			mainFooter,
			countTo
		},
		data() {
			return {
				mainCountLoaded: false,
				childCountLoaded: false,
				activeIndex: '1',
				activeService: 1,
				serviceEea: false,
				serviceEmbeded: false,
				serviceNet: false,
				servicePlatform: false,
				serviceSim: false,
				serviceInfo: false,
				serviceVehicle: false,
				newsUrls: [
					'https://mp.weixin.qq.com/s/JB2VhSFw8Jkfj-ShkGXT5A',
					'https://mp.weixin.qq.com/s/LbAmb5DBFucPB7UOuOXfCA',
					'https://mp.weixin.qq.com/s/ViJ3zoZaI96YVouOX4c53Q',
				],
				swiperOption: {
					spaceBetween: 0, // 图片之间的间距
					centeredSlides: false, // 居中还是从图1开始
					slidesPerView: 1, // 一屏幕显示几个? 数字或者默认  auto 自动。
					notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
					// loop: true, // 循环吗
					initialSlide: 0, // 从第几个开始
					autoplay: {
						delay: 5000, // 等5秒下一个
						disableOnInteraction: false // 中间滑动一下，取消自动吗？
					},
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					}, // 下按钮
					speed: 800, // 滑动时候动画的速度
					paginationClickable: true, // 下面按钮让点吗
					navigation: {
						prevEl: '.swiper-button-prev', // 左侧按钮
						nextEl: '.swiper-button-next' // 右侧按钮
					},
					// effect: 'fade', // 渐入效果
					watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
					watchSlidesVisibility: true // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
					// autoHeight: true  // 图片高度自适应
				},
				chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
			}
		},
		methods: {
			linkUrl(url) {
				window.open(url, '_blank') // 在新窗口打开外链接
				// window.location.href =this.indexro;  //在本页面打开外部链接
			},
			handleScroll() {
				let mainTop = this.$refs.countMain.getBoundingClientRect().top
				let childTop = this.$refs.countChild.getBoundingClientRect().top
				if (mainTop < document.documentElement.clientHeight && !this.mainCountLoaded) {
					this.$refs.mainCountTo.start()
					this.mainCountLoaded = true;
				}
				if (childTop < document.documentElement.clientHeight && !this.childCountLoaded) {
					this.$refs.companyCountTo.start()
					this.$refs.fastCountTo.start()
					this.$refs.doneCountTo.start()
					this.$refs.memberCountTo.start()
					this.childCountLoaded = true;
				}
			},
		},
		mounted() {
			new this.$wow.WOW().init()
			document.title = "东信创智-汽车电子第三方技术服务供应商";
			window.addEventListener("scroll", this.handleScroll)
			const vm = this
			this.handleScroll();
			this.swiper.on('slideChangeTransitionStart', function() {
				vm.activeService = vm.swiper.activeIndex + 1
			})
			this.swiper.on('touchEnd', function() {
				vm.activeService = vm.swiper.activeIndex + 1
			})
		},
		destroyed() {
			window.removeEventListener("scroll", this.handleScroll);
		},
		computed: {
			swiper() {
				return this.$refs.mySwiper.swiper
			}
		}
	}
</script>