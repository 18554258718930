<template>
	<div>
		<main-nav :activeIndex="activeIndex" @checkRouter="checkRouter"></main-nav>
		<div class="resource-banner solution-header-container">
			<div class="solution-header-text" style="margin-top: -80px">
				<h1 class="wow fadeInLeft">资源共享</h1>
				<p class="wow fadeInRight">RESOURCE SHARING</p>
			</div>
		</div>
		<anchor-area ref="courseTrain" anchorName="courseTrain" class="aboutProject-intro" v-if="isMobile == false">
			<template slot="content">
				<div class="container" style="padding-top: 160px">
					<div class="normal-title">培训课程</div>
					<div class="border-line">
						<div class="rectangle">
							<div class="rectangle-center">
								<el-row>
									<el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
										<div class="intro-content">
											<div class="project-title">课程简介</div>
											<p>
												东信创智培训课程是基于多年经验积累以及实验经验总结的，面向于OEM及Tier1的精品课程，可适用于不同学员特点(参考工作岗位1年限，知识掌握程度等)及能力程度。
											</p>
										</div>
									</el-col>
									<el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
										<el-row>
											<el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
												<div class="intro-content1">
													<div class="project-title1">授课形式及教材</div>
													<p>授课形式：线上/线下(根据客户需求)<br>教材：电子版(免费)/纸质版(收费)</p>
												</div>
											</el-col>
											<el-col :xs="0" :sm="8" :md="8" :lg="8" :xl="8">
												<img class="resource-book"
													src="../../assets/images/resource-projecto.svg">
											</el-col>
										</el-row>
										<el-row>
											<el-col :xs="0" :sm="6" :md="6" :lg="6" :xl="6">
												<img class="resource-dayu"
													src="../../assets/images/resource-projecti.svg">
											</el-col>
											<el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18">
												<div class="intro-content2">
													<div class="project-title2">面向对象</div>
													<p>系统工程师、硬件工程师、软件工程师、<br>
														测试工程师、网络工程师、标定工程师等。</p>
												</div>
											</el-col>
										</el-row>
									</el-col>
								</el-row>
							</div>
						</div>
					</div>
				</div>
			</template>
		</anchor-area>
		<anchor-area ref="courseTrain" anchorName="courseTrain" class="courseTrain-intro wow fadeInRight"
			v-if="isMobile">
			<template slot="content">
				<div class="container">
					<div class="normal-title">培训课程</div>
					<div class="all-course-intro">
						<el-row>
							<el-col :span="24">
								<div class="intro-content">
									<h3>课程简介</h3>
									<p>东信创智培训课程是基于多年经验积累以及实验经验总结的，面向于OEM及Tier1的精品课程，可适用于不同学员特点(参考工作岗位1年限，知识掌握程度等)及能力程度。
									</p>
								</div>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="24">
								<div class="intro-content">
									<h3>授课形式及教材</h3>
									<p>授课形式：线上/线下(根据客户需求)<br>教材：电子版(免费)/纸质版(收费)</p>
									<p></p>
								</div>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="24">
								<div class="intro-content">
									<h3>面向对象</h3>
									<p>系统工程师、硬件工程师、软件工程师、测试工程师、网络工程师、标定工程师等。</p>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</template>
		</anchor-area>
		<div class="project-intro wow fadeInRight">
			<div class="container">
				<el-tabs v-model="activeTrainType" class="resource-tab">
					<el-tab-pane v-for="(item, index) in trainList" :label="item.trainType" :name="item.trainType"
						:key="index">
						<swiper :options="swiperOption" ref="mySwiper">
							<swiper-slide
								v-for="(im, ix) in (isMobile ? Math.ceil(item.detailList.length / 4) : Math.ceil(item.detailList.length / 8))"
								:key="ix">
								<el-row :gutter="15">
									<template v-for="(itm, idx) in item.detailList">
										<el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6"
											v-if="isMobile ? (idx > ix * 4 - 1 && idx < (ix + 1) * 4) : (idx > ix * 8 - 1 && idx < (ix + 1) * 8)"
											:key="idx">
											<el-card class="train-card">
												<img v-if="item.trainType === '协议培训'"
													:src="require('../../assets/images/train-course/train-protocol.jpeg')"
													style="width: 100%">
												<img v-else-if="item.trainType === '工具培训'"
													:src="require('../../assets/images/train-course/train-tool.jpeg')"
													style="width: 100%">
												<img v-else-if="item.trainType === '工具基础-软件'"
													:src="require('../../assets/images/train-course/train-tool-software.jpeg')"
													style="width: 100%">
												<img v-else
													:src="require('../../assets/images/train-course/train-tool-hardware.jpeg')"
													style="width: 100%">
												<div class="card-info">
													<div class="card-title">{{ itm.trainTitle }}</div>
													<el-tooltip class="item" effect="light" :content="itm.trainDescr"
														placement="top">
														<div class="card-descr">{{ itm.trainDescr }}</div>
													</el-tooltip>
													<div class="card-footer">
														<div>课程：{{ itm.during }}小时</div>
														<el-button class="apply-button" size="small" v-if="itm.url"
															@click="linkUrl(itm.url)">
															<span class="gradient-text">立即观看</span>
														</el-button>
														<el-button class="apply-button" size="small" v-else
															@click="linkUrl('https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0YeyOOVZaT4f')">
															<span class="gradient-text">立即报名</span>
														</el-button>
													</div>
												</div>
											</el-card>
										</el-col>
									</template>
								</el-row>
							</swiper-slide>
							<div class="swiper-pagination" slot="pagination"
								v-if="parseInt(item.detailList.length / 8) + 1 > 1"></div>
						</swiper>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
		<anchor-area ref="downloadInfo" anchorName="downloadInfo" class="section-download wow fadeInLeft">
			<template slot="content">
				<div class="container">
					<div class="normal-title">资料下载</div>
					<div class="download-group">
						<el-collapse v-model="activeNames">
							<el-collapse-item v-for="(item, index) in downloadList" :title="item.productName"
								:name="item.productId" :key="index">
								<template slot="title">
									<div class="download-group-title">
										<img src="../../assets/images/logo-vector.png">{{ item.productName }}
									</div>
								</template>
								<div class="download-file-group">
									<el-row :gutter="0">
										<el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8"
											v-for="(itm, idx) in item.versionList" :key="idx">
											<div class="download-item">
												<i class="ri-archive-stack-line"></i>
												<el-button size="mini" class="download-button"
													@click="linkUrl(itm.versionUrl)">
													<i class="ri-download-2-line"></i>
												</el-button>
												<div class="version-info">
													<div class="version-name">{{ itm.versionName }}</div>
													<div class="version-extra"><i class="ri-calendar-line"></i>
														{{ itm.updateDate }}<span><i class="ri-file-list-line"></i>
															{{ itm.versionSize }}</span>
													</div>
												</div>
											</div>
										</el-col>
									</el-row>
								</div>
							</el-collapse-item>
						</el-collapse>
					</div>
				</div>
			</template>
		</anchor-area>
		<anchor-area ref="techPages" anchorName="techPages" class="technology-intro wow fadeInRight">
			<template slot="content">
				<div class="container">
					<div class="normal-title">技术好文</div>
					<div class="technology-row" @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
						@mouseleave="$refs.mySwiper.swiper.autoplay.start()">
						<swiper :options="swiperOption" ref="mySwiper">
							<swiper-slide
								v-for="(im, ix) in (isMobile ? techNewsList.length : Math.ceil(techNewsList.length / 4))"
								:key="ix">
								<el-row :gutter="15">
									<template v-for="(itm, idx) in techNewsList">
										<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="overflow: hidden;"
											v-if="isMobile ? (idx > ix * 1 - 1 && idx < (ix + 1) * 1) : (idx > ix * 4 - 1 && idx < (ix + 1) * 4)"
											:key="idx">
											<div class="position-img">
												<img :src="itm.img" class="card-cover" @click="linkUrl(itm.url)">
												<el-card :body-style="{ padding: '0px' } " class="technology-card"
													@click.native="linkUrl(itm.url)">
													<div class="top-block">
														<span class="top-content">{{ itm.techTitle }}</span>
													</div>
													<el-row>
														<el-col :span="6">
															<div class="pree-block">
																<span class="pree-content">{{ itm.techType }}</span>
															</div>
														</el-col>
														<el-col :span="18">
															<div class="pree1-content">
																<span>{{ itm.techDescr }}</span>
															</div>
															<div class="pree-button">
																<el-button type="text"
																	@click.native="linkUrl(itm.url)">查看详情<i
																		class="ri-arrow-right-s-line"></i></el-button>
															</div>
														</el-col>
													</el-row>
												</el-card>
											</div>
										</el-col>
									</template>
								</el-row>
							</swiper-slide>
							<div class="swiper-pagination" slot="pagination"
								v-if="parseInt(techNewsList.length / 4) + 1 > 1"></div>
						</swiper>
					</div>
				</div>
			</template>
		</anchor-area>
		<anchor-area ref="companyInfo" anchorName="companyInfo" class="company-intro wow fadeInLeft">
			<template slot="content">
				<div class="container">
					<div class="normal-title">市场活动</div>
					<div class="market-activities">
						<el-row>
							<!--              <el-col :xs="24" :sm="24" :md="24" :lg="13" :xl="13">-->
							<!--                <div class="left-img" @click="linkUrl('https://mp.weixin.qq.com/s/n_QQF55WGKbe0iVRTgxOAg')">-->
							<!--                  <div class="img-container">-->
							<!--                    <img src="../../assets/images/news/resource-company.png">-->
							<!--                  </div>-->
							<!--                  <div class="top-content">-->
							<!--                    <span>喜获殊荣丨东信创智成功获批辽宁省“专精特新”中小企业！</span>-->
							<!--                  </div>-->
							<!--                  <div class="bottom-content">-->
							<!--                    <span>近日，辽宁省工业和信息化厅公布了2023年度首批“专精特新”中小企业名单，沈阳东信创智科技有限公司荣登榜单，被认定为辽宁省“专精特新”中小企业。</span>-->
							<!--                  </div>-->
							<!--                </div>-->
							<!--              </el-col>-->

							<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
								<el-row class="activities-line">
									<el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
										<div class="img-container">
											<img src="../../assets/images/market-activity-07.jpg"
												@click="linkUrl('https://mp.weixin.qq.com/s/XIjKhzHYQMUPg8ysYFHFcg')">
										</div>
									</el-col>
									<el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
										<div class="activities-content"
											@click="linkUrl('https://mp.weixin.qq.com/s/XIjKhzHYQMUPg8ysYFHFcg')">
											<span>“创”议研讨丨CSM数据采集系统在新能源汽车开发测试中的应用</span>
											<div class="content">
												在新能源汽车的开发测试过程中，往往需要对逆变器效率测试、整车电性能测试、电池包温度测试等高低压测试环境中的电流、电压、温度等信号进行精确的测量，从而确保整车的安全性及高性能。
											</div>
											<div class="date">2024-08-02</div>
										</div>
									</el-col>
								</el-row>
								<el-row class="activities-line">
									<el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
										<div class="img-container">
											<img src="../../assets/images/market-activity-06.jpg"
												@click="linkUrl('https://mp.weixin.qq.com/s/peg9cOk0vbxUOUds4B8ZaQ')">
										</div>
									</el-col>
									<el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
										<div class="activities-content"
											@click="linkUrl('https://mp.weixin.qq.com/s/peg9cOk0vbxUOUds4B8ZaQ')">
											<span>“创”议研讨丨SmartCharging充电通信协议与解决方案</span>
											<div class="content">本次研讨会将对联合充电系统Combined Charging System与ISO
												15118协议进行介绍，讲解在充电通信过程中支持数据采集与报文分析的软/硬件工具，以及便于开发与测试人员使用的充电通信协议测试方案。</div>
											<div class="date">2024-06-19</div>
										</div>
									</el-col>
								</el-row>
								<el-row class="activities-line">
									<el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
										<div class="img-container">
											<img src="../../assets/images/market-activity-05.jpg"
												@click="linkUrl('https://mp.weixin.qq.com/s/FDX6OOXmIT-vlZrTE7yI-w')">
										</div>
									</el-col>
									<el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
										<div class="activities-content"
											@click="linkUrl('https://mp.weixin.qq.com/s/2NuwNPwczYBRxAsowzLxhQ')">
											<span>“创”议研讨丨基于SusPIS-ATx的座舱仿真系统搭建与评估方法</span>
											<div class="content">
												本次直播围绕汽车智能座舱仿真测试相关评价规范和法规（如C-ICAP），引入智能座舱测试行业难点及次生问题，介绍基于SusPIS-ATx的智能座舱全域功能及性能仿真测试技术路线、系统搭建和评估方法论证。
											</div>
											<div class="date">2024-05-22</div>
										</div>
									</el-col>
								</el-row>
								<!--  <el-row class="activities-line">
                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                    <div class="img-container">
                      <img src="../../assets/images/market-activity-04.jpg" @click="linkUrl('https://mp.weixin.qq.com/s/s45lD_5KdODalG-obIyIfg')">
                    </div>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
                    <div class="activities-content" @click="linkUrl('https://mp.weixin.qq.com/s/s45lD_5KdODalG-obIyIfg')">
                      <span>“创”议研讨丨DYNA4为何在HIL闭环中备受青睐？</span>
                      <div class="content">DYNA4作为Vector旗下的车辆动力学仿真软件，因其丰富且强大的功能而享誉国内外，本次研讨会将通过理论讲解与实操演示相结合的方式，重点对DYNA4软件的基本用法及其在HIL中的应用展开介绍和讨论。</div>
                      <div class="date">2024-05-06</div>
                    </div>
                  </el-col>
                </el-row> -->
								<!--                <el-row class="activities-line">-->
								<!--                  <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">-->
								<!--                    <div class="img-container">-->
								<!--                      <img src="../../assets/images/market-activity-01.jpg" @click="linkUrl('https://mp.weixin.qq.com/s/9JKTDqGRDr8s-mH0sIdJ7Q')">-->
								<!--                    </div>-->
								<!--                  </el-col>-->
								<!--                  <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">-->
								<!--                    <div class="activities-content" @click="linkUrl('https://mp.weixin.qq.com/s/9JKTDqGRDr8s-mH0sIdJ7Q')">-->
								<!--                      <span>会议邀约丨东信创智邀你共同探讨V2X自动化测试</span>-->
								<!--                      <div class="content">未知的危险交通场景难以穷尽，基于场景的实车测试方法存在瓶颈。由于无法进行充分的道路测试，需要投入的时间和费用也不能承受针对当前V2X测试面临的痛点、难点，引入V2X自动化测试工具，可提高测试准确度及测试效率。本次研讨会着重于 对V2X全流程自动化测试的方案和测试内容进行讨论。</div>-->
								<!--                      <div class="date">2024-04-15</div>-->
								<!--                    </div>-->
								<!--                  </el-col>-->
								<!--                </el-row>-->
							</el-col>
						</el-row>
					</div>
				</div>
			</template>
		</anchor-area>
		<bz-info style="margin-top: 0px" />
		<main-footer @checkRouter="checkRouter"></main-footer>
	</div>
</template>
<script>
	import mainNav from '@/components/MainNav'
	import mainFooter from '@/components/MainFooter'
	import bzInfo from "@/components/BzInfo"
	import "@/style/resource.less"
	import AnchorArea from "@/components/AnchorArea";

	export default {
		components: {
			mainNav,
			mainFooter,
			bzInfo,
			AnchorArea
		},
		data() {
			return {
				activeIndex: '4',
				activeName: 'second',
				activeTrainType: '协议培训',
				trainList: [{
					typeId: 1,
					trainType: '协议培训',
					detailList: [{
						trainTitle: '汽车总线技术发展综述',
						during: 4,
						trainDescr: '总线技术发展历程、汽车总线技术应用、汽车总线系统开发。',
						url: 'https://www.bilibili.com/video/BV14Y4y1j7Ft?t=204.6'
					}, {
						trainTitle: 'CAN总线协议',
						during: 6,
						trainDescr: '总线技术发展历程、CAN通信机制、CAN物理层介绍。',
						url: 'https://www.bilibili.com/video/BV1nr4y1j7my?t=2.9'
					}, {
						trainTitle: 'CAN FD总线协议',
						during: 6,
						trainDescr: 'CAN FD总线协议概述、CAN FD总线协议机制、CAN FD总线协议物理层。',
						url: ''
					}, {
						trainTitle: 'LIN总线协议',
						during: 6,
						trainDescr: 'LIN总线概述、LIN协议机制。',
						url: ''
					}, {
						trainTitle: '车载Ethernet物理层和数据链路层协议',
						during: 4,
						trainDescr: '物理层技术发展历程、汽车物理层技术组成、汽车物理层技术详细介绍。',
						url: ''
					}, {
						trainTitle: '车载Ethernet TCP/IP协议',
						during: 4,
						trainDescr: '车载Ethernet TCP/IP协议概述、汽车车载Ethernet TCP/IP相关协议组成、汽车车载Ethernet TCP/IP相关协议报文。',
						url: ''
					}, {
						trainTitle: '车载Ethernet SOME/IP协议',
						during: 8,
						trainDescr: '车载Ethernet SOME/IP协议概述、汽车车载Ethernet SOME/IP报文格式、汽车车载Ethernet SOME/IP通信协议。',
						url: ''
					}, {
						trainTitle: '车载Ethernet 网络管理（UDP NM）',
						during: 4,
						trainDescr: 'AUTOSAR规范介绍、AUTOSAR网络管理、UDPNM网络管理机制、UDPNM通信行为。',
						url: ''
					}, {
						trainTitle: '车载Ethernet诊断（DoIP）',
						during: 8,
						trainDescr: 'DoIP简介、DoIP传输机制、DoIP诊断机制。',
						url: ''
					}, {
						trainTitle: '车载音视频AVB协议',
						during: 4,
						trainDescr: '车载音视频AVB概述、车载音视频AVB技术组成、车载音视频AVB技术详细介绍。',
						url: ''
					}, {
						trainTitle: 'CAN网络管理（OSEK）',
						during: 4,
						trainDescr: 'OSEK网络管理概述、OSEK节点状态、BusOff处理、OSEK状态转换。',
						url: ''
					}, {
						trainTitle: 'CAN网络管理（AUTOSAR）',
						during: 4,
						trainDescr: 'AUTOSAR规范介绍、AUTOSAR网络管理、CAN网络管理机制、网络管理对比（AUTOSAR vs OSEK）。',
						url: ''
					}, {
						trainTitle: 'CAN诊断协议（UDS）',
						during: 6,
						trainDescr: 'CAN诊断概述、CAN诊断网络层协议、CAN诊断应用层协议、统一诊断服务。',
						url: ''
					}, {
						trainTitle: 'SAE J1939协议',
						during: 8,
						trainDescr: 'SAE J1939协议概述、SAE J1939协议物理层、SAE J1939协议数据链路层、SAE J1939协议网络层、SAE J1939应用层协议、SAE J1939网络管理协议。',
						url: ''
					}, {
						trainTitle: 'AUTOSAR SecOC协议',
						during: 4,
						trainDescr: 'SecOC基础：SecOC基本原理及收发机制、新鲜度及相关机制、对称加密技术简介、SecOC仿真测试方案。',
						url: ''
					}, {
						trainTitle: 'XCP标定协议',
						during: 5,
						trainDescr: 'XCP标定协议概述、XCP标定协议的详细规范和说明、演示如何使用XCP标定协议同ECU进行通讯。',
						url: ''
					}, {
						trainTitle: 'CCP标定协议',
						during: 5,
						trainDescr: 'CCP标定协议概述、CCP标定协议的详细规范和说明、演示如何使用CCP标定协议同ECU进行通讯。',
						url: ''
					}, {
						trainTitle: 'DDS DCPS协议',
						during: 6,
						trainDescr: '掌握DDS基础及其应用、DDS通信的基本要素、DDS模型架构、QoS服务质量、内置主题、交互模型分析、IDL简介。',
						url: ''
					}, {
						trainTitle: 'DDS-RTPS概述',
						during: 6,
						trainDescr: 'RTPS协议概述、平台无关模型（PIM）、RTPS实现DDS QoS和高级DDS功能、平台特定模型（PSM）、数据封装。',
						url: ''
					}, {
						trainTitle: '车载TSN协议基础',
						during: 8,
						trainDescr: 'Qbv协议培训、Qbu协议培训、CB协议培训、Qci协议培训、综述、流过滤器实例表、流门实例表、流量计量实例表等。',
						url: ''
					}, {
						trainTitle: 'VTD场景仿真软件接口协议',
						during: 8,
						trainDescr: 'RDB协议基础、SCP协议基础、第三方软件联调等。',
						url: ''
					}]
				}, {
					typeId: 2,
					trainType: '工具培训',
					detailList: [{
						trainTitle: 'CAPL编程开发',
						during: 8,
						trainDescr: 'CAPL简介、CAPL应用介绍、实际使用。',
						url: ''
					}, {
						trainTitle: 'CAN总线测试技术',
						during: 4,
						trainDescr: '测试概述、测试工具、测试环境、测试方法。',
						url: ''
					}, {
						trainTitle: 'LIN总线测试技术',
						during: 4,
						trainDescr: '测试概述、测试工具、测试环境、测试方法。',
						url: ''
					}, {
						trainTitle: '车载Ethernet测试技术',
						during: 4,
						trainDescr: '测试内容、测试规范及标准、Upper Tester内容。',
						url: ''
					}, {
						trainTitle: 'Upper Tester开发及集成技术',
						during: 8,
						trainDescr: '阐述术语定义、主要功能、应用环境和应用目的。',
						url: ''
					}, {
						trainTitle: '基于PREEvision的CP SOA建模',
						during: 16,
						trainDescr: '基于PREEvision的SOA设计理念、与传统网络设计差异。',
						url: ''
					}, {
						trainTitle: 'DOIP测试技术',
						during: 6,
						trainDescr: 'DOIP测试技术。',
						url: ''
					}, {
						trainTitle: '基于PREEvision的AP SOA建模',
						during: 8,
						trainDescr: '介绍基于PREEvision的AP ARXML设计流程、服务序列化、软件设计，APP设计、硬件设计、软件部署、服务实例设计、SD配置等。',
						url: ''
					}, {
						trainTitle: 'DOIP测试技术',
						during: 4,
						trainDescr: '测试概述、测试工具、测试环境、DOIP测试方法，基本通信内容。',
						url: ''
					}, {
						trainTitle: '整车功能集成测试',
						during: 8,
						trainDescr: '整车功能集成测试流程、测试管理及测试计划制定、问题管理流程、Labcar测试与实车功能测试。',
						url: ''
					}, {
						trainTitle: 'PREEvison二次开发（基础）',
						during: 12,
						trainDescr: 'PREEvision应用开发篇-Rule、PREEvision应用开发篇-Metrics、PREEvision应用开发篇-Reports、PREEvision应用开发篇-Table。',
						url: ''
					}]
				}, {
					typeId: 3,
					trainType: '工具基础-软件',
					detailList: [{
						trainTitle: 'CANoe基础',
						during: 8,
						trainDescr: 'CANoe在汽车V-Model开发中的作用、CANoe测量分析、仿真、数据记录。',
						url: ''
					}, {
						trainTitle: 'CANalyzer基础',
						during: 8,
						trainDescr: 'CANalyzer在汽车 V-Model开发中的作用、 CANalyzer测量分析、数据记录。',
						url: ''
					}, {
						trainTitle: 'CANape基础',
						during: 4,
						trainDescr: '标定数据库文件、CANape标定工程创建、CANape标定与测量等操作、离线数据分析功能。',
						url: ''
					}, {
						trainTitle: 'CANdelaStudio基础',
						during: 4,
						trainDescr: '诊断数据库模板、数据库属性定义、通用诊断服务定义、数据类型定义。',
						url: ''
					}, {
						trainTitle: 'ODXStudio基础',
						during: 4,
						trainDescr: '处理ODX数据：快速载入、编辑和保存ODX数据、导入CANdelaStudio创建的CDD文件。',
						url: ''
					}, {
						trainTitle: 'CANoe.Option DiVa基础',
						during: 4,
						trainDescr: '诊断规范、工程测试配置、自动测试用例生成、测试结果分析。',
						url: ''
					}, {
						trainTitle: 'CANoe Option Scope基础',
						during: 4,
						trainDescr: '硬件与软件配置、数据处理、测试执行与数据分析、自动测试程序。',
						url: ''
					}, {
						trainTitle: 'CANoe Option Ethernet基础',
						during: 4,
						trainDescr: 'CANoe Option Ethernet设置、总线监测与报文发送、数据记录与脱机分析。',
						url: ''
					}, {
						trainTitle: 'CANoe Option LIN 基础',
						during: 4,
						trainDescr: 'CANoe Option LIN设置、总线监测与报文发送、数据记录与脱机分析。',
						url: ''
					}, {
						trainTitle: 'CANoe Option J1939 基础',
						during: 4,
						trainDescr: 'CANoe Option J1939设置、总线监测与报文发送、数据记录与脱机分析。',
						url: ''
					}, {
						trainTitle: 'CANoe Option CANopen基础',
						during: 4,
						trainDescr: 'CANoe Option CANopen设置、总线监测与报文发送、CANopen信号的仿真、数据记录与脱机分析。',
						url: ''
					}, {
						trainTitle: 'CANoe Option Car2x 基础',
						during: 6,
						trainDescr: 'CANoe Option Car2x基本功能和应用、CANoe Option Car2x的优势。',
						url: ''
					}, {
						trainTitle: 'CANoe Option Smart Charging基础',
						during: 4,
						trainDescr: 'CANoe Option CANopen设置、总线监测与报文发送、CANopen信号的仿真、数据记录与脱机分析。CANoe Option.Smartcharging介绍、支持的充电通信协议、VH5110（A）CCSListener设备使用、充电测试方案介绍。',
						url: ''
					}, {
						trainTitle: 'vTESTstudio基础',
						during: 8,
						trainDescr: 'vTESTstudio的设计理念、图形化客户接口、测试工程结构、系统环境设置。',
						url: ''
					}, {
						trainTitle: 'vFlash基础',
						during: 4,
						trainDescr: '通过不同案例实现对于不同Flash刷写规范的支持。',
						url: ''
					}, {
						trainTitle: 'Indigo基础',
						during: 4,
						trainDescr: '图形化显示诊断故障代码（DTC）、利用诊断控制台可以直接访问诊断服务、显示发送和接收的诊断数据。',
						url: ''
					}, {
						trainTitle: 'PREEvision基础',
						during: 24,
						trainDescr: 'PREEvision的设计理念、需求规范设计、功能及零部件规范设计、网络规范设计、软件使用、建模示例。',
						url: ''
					}, {
						trainTitle: 'DYNA4车辆动力学模型基础',
						during: 8,
						trainDescr: 'VTD软件架构、车辆模型介绍及使用、传感器模型介绍及使用、场景模型介绍及使用。',
						url: ''
					}, {
						trainTitle: 'VTD场景仿真软件基础',
						during: 8,
						trainDescr: 'VTD软件架构、VTD工具链、VTD软件可以为客户提供哪些服务以及VTD的优势。',
						url: ''
					}, {
						trainTitle: 'CANoe Smart Charging基础',
						during: 4,
						trainDescr: 'CANoe Smart Charging基础',
						url: ''
					}, {
						trainTitle: 'DYNA4车辆动力学模型基础',
						during: 8,
						trainDescr: 'DYNA4车辆动力学模型基础',
						url: ''
					}]
				}, {
					typeId: 4,
					trainType: '工具基础-硬件',
					detailList: [{
						trainTitle: 'VN1600系列基础',
						during: 4,
						trainDescr: '硬件使用、测试示例、功能简介、系统配置。',
						url: ''
					}, {
						trainTitle: 'VN5600系列基础',
						during: 4,
						trainDescr: '硬件使用、测试示例、功能简介、系统配置。',
						url: ''
					}, {
						trainTitle: 'VH6501基础',
						during: 4,
						trainDescr: '硬件使用、测试示例、硬件构成、功能简介（网络接口卡、干扰CAN/CAN FD报文制定位）。',
						url: 'https://www.bilibili.com/video/BV1Ye411F7bW?t=2.7'
					}, {
						trainTitle: 'VH1160基础',
						during: 4,
						trainDescr: '硬件使用、测试示例、硬件构成及连接方式、功能简介（终端控制/测量、设备输入/输出）、供电形式。',
						url: ''
					}, {
						trainTitle: 'GL数据记录仪基础',
						during: 4,
						trainDescr: '硬件使用、记录示例、GL数据记录仪各个系列的特性、功能区别。',
						url: 'https://www.bilibili.com/video/BV1WW4y147Ni?t=1.9'
					}, {
						trainTitle: 'VT System基础',
						during: 4,
						trainDescr: '学习VT系统及板卡的基本功能及配置、学习CANoe中集成板卡（VT1104A、VT2004A、VT2820等）配置的用户界面及原理图。',
						url: ''
					}, {
						trainTitle: 'VN8900系列基础',
						during: 4,
						trainDescr: '硬件使用、测试示例、功能简介、系统配置、特点（如：VN8911/VN8914的性能、与其它总线接口卡的连接等）。',
						url: ''
					}, {
						trainTitle: 'CSM基础',
						during: 4,
						trainDescr: '硬件介绍，线束介绍，设备与线束连接讲解，CSM上位机使用方法，数据分析。',
						url: ''
					}, {
						trainTitle: 'CSM基础',
						during: 4,
						trainDescr: 'CSM基础',
						url: ''
					}]
				}],
				techNewsList: [{
						techTitle: '基于PREEvision的UML设计',
						techType: 'PREEvision',
						img: require('../../assets/images/resource-tech/resource-tech-image-43.jpg'),
						techDescr: '在软件行业，UML系统开发初期需要做需求获取、需求分析等工作... ',
						url: 'https://mp.weixin.qq.com/s/XJRmJYPmjxtImQjtQ4iDxA'
					},{
						techTitle: 'SecOC协议：高效自动化测试策略',
						techType: 'SecOC',
						img: require('../../assets/images/resource-tech/resource-tech-image-42.jpg'),
						techDescr: '随着汽车网联化程度的不断提高，车联网信息安全的风险也在逐渐增加... ',
						url: 'https://mp.weixin.qq.com/s/21Zfr8d564Ye1Vt3_ybt2g'
					},{
						techTitle: 'DDS—RTPS一致性测试案例分析',
						techType: 'DDS',
						img: require('../../assets/images/resource-tech/resource-tech-image-41.jpg'),
						techDescr: 'RTPS一致性测试主要测试不同供应商开发的DDS协议栈能否按RTPS协议规定的方式进行通信... ',
						url: 'https://mp.weixin.qq.com/s/XSt6jjaUcCOtWv2fCk6rWA'
					},{
						techTitle: 'CANoe中DBC数据库的制作和使用',
						techType: 'CANoe',
						img: require('../../assets/images/resource-tech/resource-tech-image-40.jpg'),
						techDescr: '在日常工作中，CANdb++是一个与我们密切相关的重要工具... ',
						url: 'https://mp.weixin.qq.com/s/v2_PQvA4nfInuRAF5okvEw'
					},{
						techTitle: '浅谈丨功能安全测试，汽车的守护者',
						techType: '功能安全',
						img: require('../../assets/images/resource-tech/resource-tech-image-39.jpg'),
						techDescr: '随着新能源汽车迅猛的发展，各类车型频频面世，同时辅助驾驶/自动驾驶等智驾功能也在不断迭代... ',
						url: 'https://mp.weixin.qq.com/s/sKS5FK-o6yZYt97TE_vP9g'
					},{
						techTitle: 'TLS测试：确保通信安全的关键步骤',
						techType: 'TLS测试',
						img: require('../../assets/images/resource-tech/resource-tech-image-38.jpg'),
						techDescr: '随着智能网联汽车技术的发展，车辆在行驶过程中会产生大量数据，包括车辆控制、导航、通信... ',
						url: 'https://mp.weixin.qq.com/s/mZFhRiYVqA51N_ZCMJp7Ug'
					},{
						techTitle: 'vTESTstudio：智能驾驶开发的高效测试与验证工具',
						techType: 'vTESTstudio',
						img: require('../../assets/images/resource-tech/resource-tech-image-37.jpg'),
						techDescr: '随着汽车“新四化”的深入发展，电子电气系统越来越复杂，电子电气架构革新和拓展势在必行... ',
						url: 'https://mp.weixin.qq.com/s/-Zz_JzWxkXHxeqTaU5m2wg'
					},{
						techTitle: '高效自动化测试，引领汽车座舱新纪元——实车篇',
						techType: '实车测试',
						img: require('../../assets/images/resource-tech/resource-tech-image-36.jpg'),
						techDescr: '作为智能网联汽车的核心组成部分，智能座舱不仅是驾驶者与车辆互动的桥梁... ',
						url: 'https://mp.weixin.qq.com/s/k2gXUednRsLRvG8IkkrQRg'
					},{
						techTitle: '基于PREEvision的SOA服务建模——DDS',
						techType: 'PREEvision',
						img: require('../../assets/images/resource-tech/resource-tech-image-35.jpg'),
						techDescr: '随着汽车“新四化”的深入发展，电子电气系统越来越复杂，电子电气架构革新和拓展势在必行... ',
						url: 'https://mp.weixin.qq.com/s/JEV6AJjEYqik48bmwDT2pQ'
					},
					{
						techTitle: '智能部件刷写技术详解',
						techType: '智能部件',
						img: require('../../assets/images/resource-tech/resource-tech-image-34.jpg'),
						techDescr: '车载ECU是汽车中负责控制的核心组件，随着汽车行业向智能化和网络化的快速发展... ',
						url: 'https://mp.weixin.qq.com/s/x-Un7wQrz8yyU0R8Q8HuEg'
					},
					{
						techTitle: '修复指南：关于CANoe Trace窗口Column Title不显示的问题',
						techType: 'CANoe Trace',
						img: require('../../assets/images/resource-tech/resource-tech-image-33.jpg'),
						techDescr: '由于Windows系统更新导致CANoe全版本Trace窗口的Column条目栏和条目栏中的过滤器无法显示... ',
						url: 'https://mp.weixin.qq.com/s/xZmVscL-KNHDiVYTAm3bNw'
					},
					{
						techTitle: 'CAN XL协议标准在CANoe中的应用',
						techType: 'CAN XL',
						img: require('../../assets/images/resource-tech/resource-tech-image-32.jpg'),
						techDescr: '众所周知，CAN通信技术在汽车领域中，有着非常广泛的应用... ',
						url: 'https://mp.weixin.qq.com/s/WGf6oeCWEiPTxH5uZnEoIQ'
					},
					{
						techTitle: 'OBD 测试方案——为新能源汽车保驾护航',
						techType: 'OBD',
						img: require('../../assets/images/resource-tech/resource-tech-image-31.jpg'),
						techDescr: '随着环保意识的增强和能源结构的调整，新能源汽车在全球范围内得到了迅速发展。新能源汽车主要包括纯电动汽车... ',
						url: 'https://mp.weixin.qq.com/s/mrmBi32vm6RSo5x3rkuU2A'
					},
					{
						techTitle: '聊聊AUTOSAR：基于Vector MICROSAR的TC8测试开发方案',
						techType: 'AUTOSAR',
						img: require('../../assets/images/resource-tech/resource-tech-image-30.jpg'),
						techDescr: '车载以太网技术作为汽车智能化和网联化的重要组成部分，正逐步成为现代汽车网络架构的核心，已广泛应用于汽车诊断（如OBD）、ECU软件更新... ',
						url: 'https://mp.weixin.qq.com/s/1twao2w0RuBp7e6tYQYCkg'
					},
					{
						techTitle: 'DDS—DCPS测试策略介绍及实际案例分析',
						techType: 'DDS',
						img: require('../../assets/images/resource-tech/resource-tech-image-29.jpg'),
						techDescr: 'DDS中间件是一个软件层，它从操作系统、网络传输和低级数据格式的细节中抽象出应用程序... ',
						url: 'https://mp.weixin.qq.com/s/mZZ3kbZhmAW7AGloNdM1-A'
					},
					{
						techTitle: 'DYNA4技术分享系列：DYNA4在底盘域的应用',
						techType: 'DYNA4',
						img: require('../../assets/images/resource-tech/resource-tech-image-28.png'),
						techDescr: '在汽车行业波澜壮阔的电动化、数字化与智能化浪潮中，底盘技术正经历着前所未有的蜕变，从传统的坚固基石跃升为集电动驱动与智能操控于一体的核心灵魂... ',
						url: 'https://mp.weixin.qq.com/s/JPNNarhEWRzgAknpfvHKwQ'
					},
					{
						techTitle: 'CSM数采系统助力高压动力系统效率测量',
						techType: 'CSM数采系统',
						img: require('../../assets/images/resource-tech/resource-tech-image-27.jpg'),
						techDescr: '在新能源汽车的发展过程中，续航能力是一个绕不开的话题，这就意味着需要对电动动力系统进行测试... ',
						url: 'https://mp.weixin.qq.com/s/FJsMaPDFVSyAOiGMx6qyFw'
					}, {
						techTitle: '革新测试管理丨统一测试管理平台如何实现远程、协同、自动化？',
						techType: '统一测试管理平台',
						img: require('../../assets/images/resource-tech/resource-tech-image-26.jpg'),
						techDescr: '当下汽车电子测试领域随着不断发展，自动化、智能化的软硬件一体测试解决方案已经成为趋势... ',
						url: 'https://mp.weixin.qq.com/s/7mZVSg-SiVKrGAgjsXgPNQ'
					}, {
						techTitle: '聊聊AUTOSAR：基于DaVinci的SecOC开发与配置',
						techType: 'AUTOSAR',
						img: require('../../assets/images/resource-tech/resource-tech-image-25.jpg'),
						techDescr: '当前车载网络通讯环境越来越复杂，未采取任何安全保护的报文，一旦被伪造或者篡改，将非常危险... ',
						url: 'https://mp.weixin.qq.com/s/Y-v4n-mAS7NJvDQxksFPIg'
					}, {
						techTitle: '基于PREEvision的架构方案评估',
						techType: 'PREEvision',
						img: require('../../assets/images/resource-tech/resource-tech-image-24.jpg'),
						techDescr: '随着汽车行业的快速发展和消费者需求的日益复杂化，现代汽车已不再仅仅是机械设备的集合体... ',
						url: 'https://mp.weixin.qq.com/s/CsEiU9gUpbi6NzxSCGIPrw'
					}, {
						techTitle: '未来驾驶新纪元：打造沉浸式HMI体验座舱',
						techType: 'HMI 体验座舱',
						img: require('../../assets/images/resource-tech/resource-tech-image-23.jpg'),
						techDescr: '智能座舱作为实现汽车“第三生活空间”的核心载体和重要平台，直接影响着用户的驾驶体验和满意度... ',
						url: 'https://mp.weixin.qq.com/s/jPHI6OmXFZbpNeYZMsoe-w'
					}, {
						techTitle: 'CANoe刷写利器：掌握队列刷写的关键步骤',
						techType: 'CANoe',
						img: require('../../assets/images/resource-tech/resource-tech-image-22.jpg'),
						techDescr: '车载ECU是汽车中负责控制的核心组件，随着汽车技术的不断发展，ECU功能日渐复杂... ',
						url: 'https://mp.weixin.qq.com/s/S36NMisAp7aIqy9678_a7Q'
					}, {
						techTitle: '整车电性能测试解决方案丨为新能源汽车发展保驾护航',
						techType: 'HMI开发',
						img: require('../../assets/images/resource-tech/resource-tech-image-21.jpg'),
						techDescr: '随着汽车电子技术的发展，越来越多的电器部件被应用到整车中，给整车电气系统带来的压力越来越大...',
						url: 'https://mp.weixin.qq.com/s/BV6G9KCL9VpvjFmtcPqPrg'
					}, {
						techTitle: 'MISRA C 和MISRA C++：汽车软件安全的守护者',
						techType: 'MISRA C',
						img: require('../../assets/images/resource-tech/resource-tech-image-20.jpg'),
						techDescr: 'MISRA C规范包括一系列规则和指导原则，这些规范覆盖了编程实践、代码设计等多个方面... ',
						url: 'https://mp.weixin.qq.com/s/NHyEdPlOKSLZkz3Vi9ZbPw'
					}, {
						techTitle: '智能网联V2X测试必备工具：探究CANoe和DYNA4的应用场景',
						techType: '智能网联V2X',
						img: require('../../assets/images/resource-tech/resource-tech-image-19.jpg'),
						techDescr: 'C-V2X技术发展较快，功能应用场景丰富，测试参数复杂交叉，功能评价复杂且关系驾驶员体验...',
						url: 'https://mp.weixin.qq.com/s/3i5Pp42UiaMpY7SGYTQ7XQ'
					}, {
						techTitle: '基于MCU平台的HMI开发的性能优化与实战（上）',
						techType: 'HMI开发',
						img: require('../../assets/images/resource-tech/resource-tech-image-18.jpg'),
						techDescr: '由于平台的计算能力和存储空间受限，如何有效利用有限的资源来实现功能丰富、页面切换平滑的HMI应用...',
						url: 'https://mp.weixin.qq.com/s/-EpPvvcMYyH4Y4awqcVpQw'
					}, {
						techTitle: 'VIO System 丨适用于控制器开发前期的测试系统',
						techType: 'VIO System',
						img: require('../../assets/images/resource-tech/resource-tech-image-17.jpg'),
						techDescr: '低成本解决方案VIO System，使得在开发前期不仅可以进行总线通讯测试，也可以同时进行I/O信号测试。',
						url: 'https://mp.weixin.qq.com/s/BHNtbiic2ppue6Dh9ariGQ'
					}, {
						techTitle: '使用小技巧：PREEvision权限管理进阶篇',
						techType: 'PREEvision',
						img: require('../../assets/images/resource-tech/resource-tech-image-06.jpg'),
						techDescr: '我们需要对各个用户在不同Project中进行权限的配置，以及在EEA工程中对各个Package配置权限。',
						url: 'https://mp.weixin.qq.com/s/Rb9SsFp07OrNDPlM_0ABSw'
					}, {
						techTitle: 'vTESTstudio如何助力SOA测试开发？',
						techType: 'SOA',
						img: require('../../assets/images/resource-tech/resource-tech-image-16.jpg'),
						techDescr: 'SOA到底是什么？要怎样对其进行测试？接下来就为大家介绍一种基于vTESTstudio的SOA测试开发方法。',
						url: 'https://mp.weixin.qq.com/s/rgf9JE0n33RzkMzWDfpjew'
					}, {
						techTitle: 'DYNA4技术分享系列：动力域中的DYNA4应用有哪些亮点？',
						techType: 'DYNA4',
						img: require('../../assets/images/resource-tech/resource-tech-image-10.jpg'),
						techDescr: '本文介绍了DYNA4软件在新能源动力域的应用，包括其支持的动力类型、建模特点以及各类闭环测试需求。',
						url: 'https://mp.weixin.qq.com/s/l8EOpYTcoGcR0TAc512PuQ'
					}, {
						techTitle: 'EV/EVSE充电测试专题-联合充电系统与ISO 15118',
						techType: 'PMA测试',
						img: require('../../assets/images/resource-tech/resource-tech-image-03.jpg'),
						techDescr: 'Combined Charging System中的Combined表明了它是一个连接器，包含直流充电接口和交流充电接口...',
						url: 'https://mp.weixin.qq.com/s/W0E-7_aA6YRt_e8Su5JX8g'
					}, {

						techTitle: '聚焦AUTOSAR FiM，揭秘汽车诊断新利器！',
						techType: 'PMA测试',
						img: require('../../assets/images/resource-tech/resource-tech-image-05.jpg'),
						techDescr: '在AUTOSAR软件架构中，FiM模块是诊断的一部分。诊断，通俗来讲，就是程序的自检。如果汽车发生...',
						url: 'https://mp.weixin.qq.com/s/gQAQpRE0h8XgRDRv_1tpew'
					}, {
						techTitle: '10BASE-T1S架构助力车载E/E领域，引领汽车产业迈向智能化新纪元！',
						techType: 'PMA测试',
						img: require('../../assets/images/resource-tech/resource-tech-image-15.jpg'),
						techDescr: '为了充分顺应新架构特点，全面普及以太网以替代CAN、FlexRay等低速网络，将以太网应用到传感器...',
						url: 'https://mp.weixin.qq.com/s/gtkl86CZClfiVF8vqwcKIA'
					}, {
						techTitle: '聊聊车载以太网PMA测试解决方案',
						techType: 'PMA测试',
						img: require('../../assets/images/resource-tech/resource-tech-image-14.jpg'),
						techDescr: '为了确保以太网信号的稳定性，1000BASE-T1物理层PMA 测试受到了广泛的关注...',
						url: 'https://mp.weixin.qq.com/s/9HMD3rNOyo7Rr3GCGLSqdQ'
					}, {
						techTitle: '关于PREEvision二次开发的常见问题及解析（下）',
						techType: '架构开发',
						img: require('../../assets/images/resource-tech/resource-tech-image-06.jpg'),
						techDescr: '本次我们来分享Report多级标题格式不统一和通过Metric不能直接设置特定构件属性值问题...',
						url: 'https://mp.weixin.qq.com/s/4U2S0-0-EH-u3oy0zDQMwA'
					}, {
						techTitle: '大模型时代下的自动驾驶研发测试工具链-SimCycle',
						techType: '实车测试',
						img: require('../../assets/images/resource-tech/resource-tech-image-13.jpg'),
						techDescr: '自动驾驶行业更是AI发展的重要应用方向之一，一系列测试验证方法以及工具链也在随之不断更新突破...',
						url: 'https://mp.weixin.qq.com/s/QguBiN-L2BmRZBBrr9p3qg'
					}, {
						techTitle: 'DDS数据分发服务—提升汽车领域数据传输效率',
						techType: 'DDS数据分发',
						img: require('../../assets/images/resource-tech/resource-tech-image-12.jpg'),
						techDescr: '数据分发服务（DDS）作为一项先进的数据传输解决方案，在汽车行业中扮演着重要的角色...',
						url: 'https://mp.weixin.qq.com/s/xuafrpTathqDyMScovixzA'
					}, {
						techTitle: 'EV/EVSE充电测试专题—GB/T 27930与CANoe',
						techType: '充电测试',
						img: require('../../assets/images/resource-tech/resource-tech-image-11.jpg'),
						techDescr: 'GB/T 27930——中国智能充电协议，衍生于SAE J1939网络协议，它描述了一条包含两个网络节点...',
						url: 'https://mp.weixin.qq.com/s/wZlD1urtHD6w8F5oz9Gl1Q'
					}, {
						techTitle: '解锁汽车OTA新境界：自动化测试方案引领未来',
						techType: 'OTA测试',
						img: require('../../assets/images/resource-tech/resource-tech-image-09.jpg'),
						techDescr: '虽然在汽车上应用OTA技术是大势所趋，但由于汽车OTA要求十分严苛，车上的软件必须...',
						url: 'https://mp.weixin.qq.com/s/Oz1UboE7bxCBO3mkNBgt0g'
					}, {
						techTitle: '关于PREEvision二次开发的常见问题及解析（上）',
						techType: '架构开发',
						img: require('../../assets/images/resource-tech/resource-tech-image-06.jpg'),
						techDescr: '本期将主要介绍在开发过程中遇到的一些常见问题以及对应问题的解决方案。',
						url: 'https://mp.weixin.qq.com/s/Nb7Az2_8HLDEw7IYQ6cynw'
					}, {
						techTitle: 'DYNA4技术分享系列：一起走进DYNA4的世界',
						techType: 'DYNA4',
						img: require('../../assets/images/resource-tech/resource-tech-image-10.jpg'),
						techDescr: '看到DYNA4，很多汽车专业的小伙伴可能会不自觉地联想到dynamics动力学这个单词，没...',
						url: 'https://mp.weixin.qq.com/s/8NcfGSKmtYndYnqDwLI3iA'
					}, {
						techTitle: 'EV/EVSE充电测试专题—采集设备与测试包工具篇',
						techType: '充电测试',
						img: require('../../assets/images/resource-tech/resource-tech-image-03.jpg'),
						techDescr: '通过上一期的《EV/EVSE充电测试专题—CANoe选项包篇》，我们了解了Vector工具链中...',
						url: 'https://mp.weixin.qq.com/s/L7Pzst_pDTxZCvHJRl5yOg'
					}, {
						techTitle: 'Qt for MCUs：为嵌入式微控制器(MCU)提供高性能HMI开发解决方案',
						techType: 'Qt for MCUs',
						img: require('../../assets/images/resource-tech/resource-tech-image-01.jpg'),
						techDescr: '随着新能源汽车的高速发展，智能座舱技术在不断创新进步，致力于为用户带来更好的体...',
						url: 'https://mp.weixin.qq.com/s/Cq95XorAXHDl7TnbIbifRA'
					}, {
						techTitle: '新能源汽车动力域：OBC/DCDC HIL仿真测试',
						techType: '新能源汽车动力域',
						img: require('../../assets/images/resource-tech/resource-tech-image-02.jpg'),
						techDescr: '在这个注重环境保护、解决能源危机、智能网联化的时代，汽车产业成为了时代的关注焦点...',
						url: 'https://mp.weixin.qq.com/s/a3iTuFGemV3Lh_33MrQ-8g'
					}, {
						techTitle: 'EV/EVSE充电测试专题—CANoe选项包篇',
						techType: '充电测试',
						img: require('../../assets/images/resource-tech/resource-tech-image-03.jpg'),
						techDescr: '随着电动汽车进入大众市场的创新解决方案越来越多，其中一个非常重要的部分就是充电技术...',
						url: 'https://mp.weixin.qq.com/s/lrVy3QL9SwKTiL7gv2A-mg'
					}, {
						techTitle: '智能驾驶测试的核心环节（上）',
						techType: '实车测试',
						img: require('../../assets/images/resource-tech/resource-tech-image-04.jpg'),
						techDescr: '随着汽车智能技术的蓬勃发展，汽车逐步演变成智能终端。由于智能驾驶对提升通行效率...',
						url: 'https://mp.weixin.qq.com/s/cNGNf9I7inDqYrrFRTvyQg'
					}, {
						techTitle: '智能驾驶测试的核心环节（下）',
						techType: '实车测试',
						img: require('../../assets/images/resource-tech/resource-tech-image-04.jpg'),
						techDescr: '自适应巡航控制ACC系统是对常规定速巡航功能的升级，通过摄像头和雷达来探测本车与...',
						url: 'https://mp.weixin.qq.com/s/0500oZ9vwqNmcs8AHeQfpA'
					}, {
						techTitle: '聊聊AUTOSAR：基于DaVinci的XCP开发与配置',
						techType: 'AUTOSAR',
						img: require('../../assets/images/resource-tech/resource-tech-image-05.jpg'),
						techDescr: '标定功能在汽车电子开发过程中，是不可或缺的一环。为了更好地满足动力等需求，需要...',
						url: 'https://mp.weixin.qq.com/s/VNRkrf-Dq8siV8TBB0FOoA'
					}, {
						techTitle: '基于PREEvision的数字化架构开发——数据交换',
						techType: '架构开发',
						img: require('../../assets/images/resource-tech/resource-tech-image-06.jpg'),
						techDescr: 'PREEvision的Server API技术，通过它就可以实现PREEvision和其它系统数据的实...',
						url: 'https://mp.weixin.qq.com/s/RyjXhHvcp87twpUx5tZIHA'
					}, {
						techTitle: '基于PREEvision的数字化架构开发——平台化开发',
						techType: '架构开发',
						img: require('../../assets/images/resource-tech/resource-tech-image-06.jpg'),
						techDescr: '为了打造高端智能电动汽车，各大车厂纷纷提出自己的新型架构平台，包括埃安的星灵架...',
						url: 'https://mp.weixin.qq.com/s/WkTsHCytFz3iZE1unEFxrw'
					}, {
						techTitle: '整车级高低压台架，让新能源汽车功能集成测试更简单！',
						techType: '整车级高低压台架',
						img: require('../../assets/images/resource-tech/resource-tech-image-07.jpg'),
						techDescr: '本文主要针对高低压联调台架的搭建、测试及安全进行概念上的阐述与简要的介绍。...',
						url: 'https://mp.weixin.qq.com/s/2fD643HKThW1xN9l3a-elg'
					}, {
						techTitle: '机械手 VS ADB：谁是HMI自动化测试中的适配王者？',
						techType: 'HMI自动化测试',
						img: require('../../assets/images/resource-tech/resource-tech-image-08.jpg'),
						techDescr: '随着科学技术的发展及新技术的应用，汽车产业发生了巨大变革。汽车从最初的简单交通工具，...',
						url: 'https://mp.weixin.qq.com/s/uqRFxuto1s0I5LxVAuBZGA'
					}, {
						techTitle: '别急！关于OTA测试的烦恼可以这样解决',
						techType: 'OTA测试',
						img: require('../../assets/images/resource-tech/resource-tech-image-09.jpg'),
						techDescr: '汽车电动化、智能化的到来，使得汽车从机械的代步工具逐渐成为互联网产品的承载物。辅助驾...',
						url: 'https://mp.weixin.qq.com/s/C2Ye5NeqTNaCb3j1ch2XAA'
					}
				],
				downloadList: [{
					productId: 1,
					productName: 'CANalyzer安装包',
					versionList: [{
						versionName: 'CANalyzer12.064bit.rar',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkT0Q3fjc',
						updateDate: '2022-04-20',
						versionSize: '1.7GB'
					}, {
						versionName: 'CANalyzer_13.0_SP3_64Bit.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAke4SYG01',
						updateDate: '2022-11-03',
						versionSize: '2.0GB'
					}, {
						versionName: 'CANalyzer_16_SP3_64Bit.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAk8T1WL2d',
						updateDate: '2022-11-03',
						versionSize: '3.3GB'
					}, {
						versionName: 'CANalyzer_17_64Bit.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkv01F0Kk',
						updateDate: '2023-06-29',
						versionSize: '3.5GB'
					}, {
						versionName: 'CANalyzer_64bit_14.0.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkIS0CuMp',
						updateDate: '2022-04-20',
						versionSize: '2.7GB'
					}, {
						versionName: 'CANalyzer_V11.0.96_SP4_64bit',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAk4AnN4Qj',
						updateDate: '2023-10-20',
						versionSize: '1.7GB'
					}]
				}, {
					productId: 2,
					productName: 'CANape安装包',
					versionList: [{
						versionName: 'CANape17SP3HF1.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkE1p856l',
						updateDate: '2023-08-22',
						versionSize: '2.5GB'
					}, {
						versionName: 'CANape 17.0.rar',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAk0Cd55DX',
						updateDate: '2023-08-22',
						versionSize: '6.5GB'
					}, {
						versionName: 'CANape 19.0.11 Rev 150068.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkxnvtLEJ',
						updateDate: '2022-04-20',
						versionSize: '4.0GB'
					}, {
						versionName: 'CANape20SP1HF1.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkJONDFx8',
						updateDate: '2022-06-27',
						versionSize: '4.2GB'
					}, {
						versionName: 'CANape21.0.01.12(177074)HF1.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkEXwnUKb',
						updateDate: '2023-02-21',
						versionSize: '5.5GB'
					}, {
						versionName: 'CANape_16.0_SP7.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkzh5a60I',
						updateDate: '2023-02-22',
						versionSize: '2.9GB'
					}, {
						versionName: 'CANape_V18.0_x64.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkl0QDuKD',
						updateDate: '2022-04-20',
						versionSize: '3.4GB'
					}]
				}, {
					productId: 3,
					productName: 'CANdelaStudio安装包',
					versionList: [{
						versionName: 'CANdelaStudio13.0 SP1.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAk6kjL83n',
						updateDate: '2022-08-08',
						versionSize: '509MB'
					}, {
						versionName: 'CANdelaStudio17SP1.exe',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkh5L0Oai',
						updateDate: '2022-03-13',
						versionSize: '632MB'
					}, {
						versionName: 'CANdelaStudio18-SP1.exe',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkIVPprf6',
						updateDate: '2022-03-13',
						versionSize: '706MB'
					}, {
						versionName: 'CANdelaStudio_V14.0.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkhe5LRTh',
						updateDate: '2022-04-20',
						versionSize: '570MB'
					}, {
						versionName: 'Vector CANdelaStudio 10.rar',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAk1X54PWn',
						updateDate: '2022-09-07',
						versionSize: '94MB'
					}, {
						versionName: 'Vector CANdelaStudio 12.rar',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAksfo36Uk',
						updateDate: '2022-09-07',
						versionSize: '110MB'
					}]
				}, {
					productId: 4,
					productName: 'CANoe.Diva安装包',
					versionList: [{
						versionName: 'CANoe.DiVa12.0SP3_Udpate.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkGlSD0qP',
						updateDate: '2022-04-20',
						versionSize: '52MB'
					}, {
						versionName: 'CANOE.dIVA13.0SP2_Update.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkm0avbLj',
						updateDate: '2023-11-01',
						versionSize: '56MB'
					}, {
						versionName: 'CANoe.DiVa16.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkGo7aOsJ',
						updateDate: '2022-11-30',
						versionSize: '93MB'
					}, {
						versionName: 'CANoe.DiVa 17 SP1.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkL1nV8Yb',
						updateDate: '2023-08-03',
						versionSize: '106MB'
					}, {
						versionName: 'CANoe.DiVa_V11.0_SP4.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkd0CmEZj',
						updateDate: '2023-04-17',
						versionSize: '50MB'
					}, {
						versionName: 'CANoeDiva_V14.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAk1HCXsUG',
						updateDate: '2022-04-20',
						versionSize: '60MB'
					}, {
						versionName: 'CANoe_Diva15.0.rar',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAk6iYK5yf',
						updateDate: '2022-04-27',
						versionSize: '81MB'
					}]
				}, {
					productId: 5,
					productName: 'CANoe安装包',
					versionList: [{
						versionName: 'CANoe_16_64Bit.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkPHC1hM1',
						updateDate: '2022-08-08',
						versionSize: '4.3GB'
					}, {
						versionName: 'CANoe_17_64Bit.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkKNdUZHw',
						updateDate: '2023-06-01',
						versionSize: '4.9GB'
					}, {
						versionName: 'CANoe_64bit_14.0.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkunhKvTg',
						updateDate: '2022-04-20',
						versionSize: '3.4GB'
					}, {
						versionName: 'CANoe_V11.0.96_SP4_64bit.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAks4TrUSy',
						updateDate: '2022-05-10',
						versionSize: '2.1GB'
					}, {
						versionName: 'CANoe_V15.0.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkjsqQUiG',
						updateDate: '2022-04-20',
						versionSize: '3.7GB'
					}]
				}, {
					productId: 6,
					productName: 'DaVinci安装包',
					versionList: [{
						versionName: 'DaVinci_Configurator_5.22.40_SP2.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkNjZU7Xx',
						updateDate: '2022-04-20',
						versionSize: '360MB'
					}, {
						versionName: 'DaVinci_Developer_4.7.30_SP1.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAk0qkB0WI',
						updateDate: '2022-04-20',
						versionSize: '261MB'
					}, {
						versionName: 'DaVinci_Developer_4.8.25.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkk8TUilC',
						updateDate: '2022-04-20',
						versionSize: '266MB'
					}]
				}, {
					productId: 7,
					productName: 'Indigo安装包',
					versionList: [{
						versionName: 'Indigo_9SP3.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkedhEWL1',
						updateDate: '2023-03-13',
						versionSize: '2.0GB'
					}, {
						versionName: 'Indigo_V6.0.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkmTKl0DB',
						updateDate: '2023-03-13',
						versionSize: '3.0GB'
					}]
				}, {
					productId: 8,
					productName: 'ODXstudio安装包',
					versionList: [{
						versionName: 'ODXStudio10SP3.exe',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkpzQQ1qy',
						updateDate: '2023-12-27',
						versionSize: '636MB'
					}, {
						versionName: 'ODXStudio_V8.0.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkLAqfD6W',
						updateDate: '2023-04-17',
						versionSize: '712MB'
					}, {
						versionName: 'ODXStudio_V10_P2.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAk172xm6c',
						updateDate: '2023-04-17',
						versionSize: '614MB'
					}]
				}, {
					productId: 9,
					productName: 'Picoscope驱动',
					versionList: [{
						versionName: 'PicoScope6Beta_r6_14_1.rar',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAk0MnnW4B',
						updateDate: '2023-08-02',
						versionSize: '173MB'
					}, {
						versionName: 'PicoScope_7.1.2.15463_TnM_Stable.exe',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkCURFDUD',
						updateDate: '2023-08-02',
						versionSize: '313MB'
					}]
				}, {
					productId: 10,
					productName: 'PREEvision安装包',
					versionList: [{
						versionName: 'PREEvision_Client_9.5.4_x86_64.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkrq11RZf',
						updateDate: '2022-06-27',
						versionSize: '3.5GB'
					}, {
						versionName: 'PREEvision_Client_10.0.17_x86_64_installer.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkK1a0G8r',
						updateDate: '2023-12-27',
						versionSize: '2.9GB'
					}, {
						versionName: 'PREEvision解压密码.txt',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkI6tD99N',
						updateDate: '2022-06-27',
						versionSize: '1KB'
					}]
				}, {
					productId: 11,
					productName: 'VC硬件驱动安装包',
					versionList: [{
						versionName: 'Vector Driver Setup 22.10.2 for Windows 10 and Windows 11.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkzvYEK6l',
						updateDate: '2022-06-28',
						versionSize: '1.5GB'
					}, {
						versionName: 'Vector_Driver_Setup.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkl2H90hB',
						updateDate: '2022-06-27',
						versionSize: '1.5GB'
					}, {
						versionName: 'Vector_Driver_Setup22.20.22.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAk7ctzKoz',
						updateDate: '2023-06-29',
						versionSize: '2.3GB'
					}, {
						versionName: 'Vector_Driver_Setup23.10.18.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkB14zaSw',
						updateDate: '2023-06-29',
						versionSize: '2.4GB'
					}, {
						versionName: 'Vector_Driver_Setup_20_30_5_win7win8.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAknEXlr1E',
						updateDate: '2022-06-27',
						versionSize: '1.5GB'
					}]
				}, {
					productId: 12,
					productName: 'vFlash安装包',
					versionList: [{
						versionName: 'vFlash8SP2.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkY1DHgyQ',
						updateDate: '2023-03-13',
						versionSize: '2.5GB'
					}, {
						versionName: 'vFlash8SP4.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkexXLlBL',
						updateDate: '2023-12-27',
						versionSize: '2.5GB'
					}, {
						versionName: 'vFlash_V6.0.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAk6vtj9Y8',
						updateDate: '2023-03-13',
						versionSize: '2.2GB'
					}]
				}, {
					productId: 13,
					productName: 'vTESTstudio安装包',
					versionList: [{
						versionName: 'vTESTstudio_8.3.78.SP3.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAka1JvNrc',
						updateDate: '2023-11-16',
						versionSize: '4.7GB'
					}, {
						versionName: 'vTESTstudio_V5.0.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkt3RKttT',
						updateDate: '2022-04-20',
						versionSize: '3.8GB'
					}, {
						versionName: 'vTESTstudio_V6.0.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkAouKF10',
						updateDate: '2022-04-20',
						versionSize: '3.9GB'
					}, {
						versionName: 'vTESTstudio_V7.0SP2.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkJVxmOAm',
						updateDate: '2022-08-01',
						versionSize: '3.6GB'
					}]
				}, {
					productId: 14,
					productName: '其它安装包',
					versionList: [{
						versionName: 'ASAP2Tool-Set16.0.20.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkcnM5zPy',
						updateDate: '2022-03-07',
						versionSize: '404MB'
					}, {
						versionName: 'DYNA4_R5.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkbqRI1ls',
						updateDate: '2020-12-02',
						versionSize: '2.9GB'
					}, {
						versionName: 'Setup_VLSuite_64Bit_4.2.107.exe',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkAX1IOob',
						updateDate: '2023-06-16',
						versionSize: '579MB'
					}, {
						versionName: 'vADASdeveloper_V5.0.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkBT8k9S8',
						updateDate: '2020-12-02',
						versionSize: '1.7GB'
					}, {
						versionName: 'VectorLicenseClient7.3Installer.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAk3NHWVqG',
						updateDate: '2023-08-31',
						versionSize: '468MB'
					}, {
						versionName: 'VectorLicenseClientInstaller.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkhq0xMYs',
						updateDate: '2023-04-06',
						versionSize: '426MB'
					}, {
						versionName: 'VectorLoggerSuite.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAk4mBSwTB',
						updateDate: '2022-10-11',
						versionSize: '1.2GB'
					}, {
						versionName: 'vSignalyzer_V18.0.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAk6dBhTDy',
						updateDate: '2020-12-04',
						versionSize: '1.7GB'
					}, {
						versionName: 'vVIRTUALtarget_V4.0.zip',
						versionUrl: 'https://drive.weixin.qq.com/s?k=AOEAXQcgAAkkDQAYBf',
						updateDate: '2020-12-02',
						versionSize: '577MB'
					}]
				}],
				activeNames: [1],
				swiperOption: {
					spaceBetween: 20, // 图片之间的间距
					centeredSlides: false, // 居中还是从图1开始
					slidesPerView: 1, // 一屏幕显示几个? 数字或者默认  auto 自动。
					notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
					// loop: true, // 循环吗
					initialSlide: 0, // 从第几个开始
					// autoplay: {
					//   delay: 5000, // 等5秒下一个
					//   disableOnInteraction: false // 中间滑动一下，取消自动吗？
					// },
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					}, // 下按钮
					speed: 800, // 滑动时候动画的速度
					paginationClickable: true, // 下面按钮让点吗
					navigation: {
						prevEl: '.swiper-button-prev', // 左侧按钮
						nextEl: '.swiper-button-next' // 右侧按钮
					},
					// effect: 'fade', // 渐入效果
					watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
					watchSlidesVisibility: true // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
					// autoHeight: true  // 图片高度自适应
				},
				chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
				isMobile: false,
			}
		},
		mounted() {
			document.title = "资源共享 - 东信创智"
			new this.$wow.WOW().init();
			// document.title="资源共享 - 东信创智";
			this.checkRouter(this.$route.hash);
			this.isMobile = document.documentElement.clientWidth < 1200
			window.onresize = () => {
				return (() => {
					this.isMobile = document.documentElement.clientWidth < 1200;
				})();
			}
		},
		methods: {
			linkUrl(url) {
				window.open(url, '_blank') // 在新窗口打开外链接
				// window.location.href =this.indexro;  //在本页面打开外部链接
			},
			// 检查路由后滚动至某标题
			checkRouter(routeHash) {
				switch (routeHash) {
					case "#courseTrain":
						this.$refs.courseTrain.go();
						break;
					case "#downloadInfo":
						this.$refs.downloadInfo.go();
						break;
					case "#techPages":
						this.$refs.techPages.go();
						break;
					case "#companyInfo":
						this.$refs.companyInfo.go();
						break;
					default:
						break;
				}
				this.$router.push({
					hash: routeHash
				});
			},
		}
	}
</script>
<style>

</style>